import React from 'react';

import LinkTransition from './linkTransition';

import IconInstagramWhite from '../assets/iconInstagramWhite.inline.svg';
import IconLinkedinWhite from '../assets/iconLinkedinWhite.inline.svg';
import IconMediumWhite from '../assets/iconMediumWhite.inline.svg';
import IconWhatsappWhite from '../assets/iconWhatsappWhite.inline.svg';
import IconInstagramBlack from '../assets/iconInstagramBlack.inline.svg';
import IconLinkedinBlack from '../assets/iconLinkedinBlack.inline.svg';
import IconMediumBlack from '../assets/iconMediumBlack.inline.svg';
import IconWhatsappBlack from '../assets/iconWhatsappBlack.inline.svg';

const Footer = (props) => {

    return (

        <footer className="container pt-20">

            <div className="relative h-60">
                <a href="mailto:hello@atuo.com.br" title="Entre em contato pelo e-mail" className="text-2xl md:text-xl font-light transform -rotate-90 block absolute -right-24 md:-right-20 top-36 md:top-20">
                    <span className="text-yellow">hello</span>@atuo.com.br
                </a>
            </div>

            <div className="flex flex-col md:grid grid-cols-4 md:gap-6 md:mb-16">
                
                <div className="flex items-end pb-24 border-b md:pb-0 md:border-0">
                    <a href="https://www.linkedin.com/company/agenciaatuo" title="" className="mr-6">{props.bg === 'black' ? <IconLinkedinWhite /> : <IconLinkedinBlack />}</a>
                    <a href="https://www.instagram.com/agenciaatuo" title="" className="mr-6">{props.bg === 'black' ? <IconInstagramWhite /> : <IconInstagramBlack />}</a>
                    <a href="https://medium.com/ag%C3%AAncia-atuo" title="" className="mr-6">{props.bg === 'black' ? <IconMediumWhite /> : <IconMediumBlack />}</a>
                    <a href="https://wa.me/5515991423442" title="" className="mr-6">{props.bg === 'black' ? <IconWhatsappWhite /> : <IconWhatsappBlack />}</a>
                </div>

                <div className="text-right pt-24 md:pt-0 pb-12 md:pb-0">
                    <p className="mb-5 md:mb-0 font-semibold">
                        São Paulo, SP <br />
                    </p>
                    <a href="tel:+551135228405" title="Faça uma chamada para o nosso escritório em São Paulo" className="text-xl font-regular">
                        +55 11 3522-8405
                    </a>
                </div>
                <div className="text-right pb-12 md:pb-0">
                    <p className="mb-5 md:mb-0 font-semibold">
                        Sorocaba, SP <br />
                    </p>
                    <a href="tel:+551535009778" title="Faça uma chamada para o nosso escritório em Sorocaba" className="text-xl font-regular">
                        +55 15 3500-9778
                    </a>
                </div>
                <div className="text-right pb-24 md:pb-0">
                    <p className="mb-5 md:mb-0 font-semibold">
                        Itapetininga, SP <br />
                    </p>
                    <a href="tel:+551535278114" title="Faça uma chamada para o nosso escritório em Itapetininga" className="text-xl font-regular">
                        +55 15 3527-8114
                    </a>
                </div>
            </div>

            <div className="flex text-sm mb-10 font-light opacity-80">
                <p className="flex-auto">
                    © ATUO 2022
                </p>
                <p className="flex-auto text-right">
                    <LinkTransition to="/politica-de-privacidade">
                        Termos de privacidade
                    </LinkTransition>
                </p>
            </div>

        </footer>
    );
};

export default Footer;
