import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../styles/scss/global.scss';

const Layout = ({ bg, children }) => {

    return (

        <div
            className={classNames({
                'relative': true,
                'overflow-hidden': true,
                'text-white': bg === 'black',
                'bg-zinc': bg === 'white'
            })}
        >
        
            <div className="z-10">

                <main>{children}</main>

            </div>

        </div>
    
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
